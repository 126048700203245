


































































































































import { Component, Vue, Emit } from 'vue-property-decorator';
import {Route} from 'vue-router';
import AccountForm from './../../../plugins/AccountForm';
import { AccountRequest, PostLoginResponse, MailaddressEdit, ErrorResponse } from '../../../../types/typescript-axios/api';
import axios, {AxiosResponse, AxiosError} from 'axios';
import Error from '../../../components/contents/Error.vue';
import VeeValidate from '../../../plugins/vee-validate';
import StepNavi from '../../../components/contents/stepNavi/StepNavi.vue';
import { load } from 'recaptcha-v3';
import auth from '../../../store/auth';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
]);

async function URLValidation(to: Route) {
  const res = await new Promise((resolve, reject) => {
    axios.get<AxiosResponse>(`/wp-json/moc/account/reset/${to.params.key}`)
    .then( (response) => {
      resolve(response);
    })
    .catch( (e: AxiosError) => {
      reject(e);
    });
  });
  return res;
}

@Component({
  mixins: [
    AccountForm,
    VeeValidate,
  ],
  components: {
    Error,
    StepNavi,
  },
})
export default class EditAccountMail extends Vue {
  private status: number = 200;
  private errorMessage: string = '';
  private newPassword: string = '';
  private onetimepassword: string = '';
  private secretQuestAans: string = '';

  private get stepNavi(): string {
    const $parent: any = this.$parent;
    return $parent.stepNavi;
  }

  get isLogin() {
    return this.$store.getters['auth/isLogin'];
  }

  private async beforeRouteEnter(to: Route, from: Route, next: any) {
    let res: any;
    try {
      res = await URLValidation(to);
    } catch (e) {
      let status = 500;
      if (typeof e.response !== 'undefined') {
        status = Number(e.response.status);
      }
      next((vm: {
        status: number,
      }) => {
        vm.status = status;
      });
      return;
    }
    next((vm: {status: number}) => {
      vm.status = 200;
    });
  }

  private async beforeRouteUpdate(to: Route, from: Route, next: any) {
    const $to: any = to;
    let res: any;
    try {
      res = await URLValidation(to);
    } catch (e) {
      let status = 500;
      if (typeof e.response !== 'undefined') {
        status = e.response.status;
      }
      this.status = status;
      return;
    }
    this.status = 200;
  }

  @Emit()
  private async onSubmit() {
    const $observer: any = this.$refs.observer;
    $observer.reset();
    $observer.validate().then(async (result: boolean) => {
      if (result) {
        const param: any = {
          onetimepassword: this.onetimepassword,
          upassword: this.newPassword,
          secret_quest_ans: this.secretQuestAans,
        };
        const recaptcha = await load(process.env.VUE_APP_RECAPTCHA_KEY);
        const token = await recaptcha.execute('homepage');
        param.recaptchaToken = token;
        param.token = auth.state.token;
        const nonce = await this.$store.dispatch('nonce/getData', null , {root: true});
        const query = new URLSearchParams(nonce);
        axios.put<MailaddressEdit, AxiosResponse>(
          `/wp-json/moc/account/reset/${this.$route.params.key}/edit?${query}`, param,
        )
        .then( (response) => {
          this.$router.push(`/account/reset/${this.$route.params.key}/complete/`);
        })
        .catch( (e: AxiosError) => {
          this.errorMessage = '入力内容に誤りがございます。<br>ご確認の上、再度入力してください。';
          const errorDome: any = this.$refs.errorMessage;
          window.scrollTo({
            top: window.pageYOffset + errorDome.getBoundingClientRect().top,
            behavior: 'smooth',
          });
        });
      } else {
        this.errorMessage = '入力内容に誤りがございます。<br>ご確認の上、再度入力してください。';
        Vue.nextTick()
        .then(() => {
          const errorDome: any = this.$refs.errorMessage;
          window.scrollTo({
            top: window.pageYOffset + errorDome.getBoundingClientRect().top,
            behavior: 'smooth',
          });
        });
      }
    });
  }
}
